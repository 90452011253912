<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="false">
      <div slot="content-buttons-table-header">
        <Button _key="btnCreateFinance" :title="'Larçar no Financeiro ' + selected.length" type="primary" size="small"
          :clicked="confirmCreate" />
      </div>
    </ViewTemplateWithTable>
    <Modal title="Lançar no Financeiro?" :width="800" v-show="showModal('createFinance')">
      <b-row>
        <b-col sm="6">
          <Select title="Tipo de Pagamento" :showNewRegister="false" field="typePayment" :required="false"
            :markFormDirty="false" v-model="typePayment" url="/api/v1/finance/type-payment/select-all"></Select>
        </b-col>
        <b-col sm="6">
          <Select title="Categoria" field="category" :formName="formName" :required="false"
            url="api/v1/finance/chart-of-accounts/select-all" v-model="chartOfAccounts" :showNewRegister="false">
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder :formName="formName"
            :required="false" v-model="dueDate" />
        </b-col>
        <b-col sm="6">
          <div class="div-paid">
            <CheckboxSimple :title="'Marcar como pago'" v-model="paid" />
          </div>
        </b-col>
      </b-row>
      <Confirmation :isModal="false" title="Você tem certeza?" type="primary" :confirmed="createFinance" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "NfeReceivedListView",
  components: {
    ViewTemplateWithTable,
    Button,
    Confirmation,
    Select,
    DateTime,
    CheckboxSimple,
    Modal
  },
  data() {
    return {
      id: "",
      urlGetPdf: "/api/v1/tax-document/nfe-received/get-pdf",
      urlGetXml: "/api/v1/tax-document/nfe-received/get-xml",
      urlCreateAllApi: "/api/v1/tax-document/nfe-received/create-payments-receivable",
      typePayment: { id: "" },
      chartOfAccounts: { id: "" },
      dueDate: "",
      paid: false,
      panel: {
        module: "Nota Fiscal",
        title: "Nfe Recebida",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/tax-document/nfe-received/get-all",
        showChecks: true,
        headerTable: [
          {
            field: "dataEmissao",
            title: "Emissão",
            type: "date",
            iconSearch: true,
          },
          {
            field: "nomeEmitente",
            title: "Nome Emitente",
            styleBody: "max-width: 450px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            type: "text",
          },
          {
            field: "documentoEmitente",
            title: "CNPJ/CPF Emitente",
            type: "text",
          },
          {
            field: "situacao",
            title: "Status",
            type: "class",
            fieldComparison: "situacao",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Pendente",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Autorizada",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelada",
              },
            ],
          },
          {
            field: "importado",
            title: "Financeiro",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Valor",
            field: "valorTotal",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            typeButton: "dropdown",
            classCssBody: "text-right",
            button: {
              title: "Opções",
              type: "info",
              classIcon: "fa-solid fa-chevron-down",
              size: "small",
              items: [
                {
                  title: "Baixar PDF",
                  classIcon: "fa-solid fa-file-pdf",
                  eventName: "getPdfNfe",
                },
                {
                  title: "Baixar XML",
                  classIcon: "fa-solid fa-memo-pad",
                  eventName: "getXmlNfe",
                  hr: false,
                },
              ],
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading", "cleanSelected"]),
    ...mapActions("generic", ["putApi", "getFileApi"]),
    createFinance() {

      let params = {
        url: this.urlCreateAllApi,
        obj: {
          ids: this.selected,
          typePayment: this.typePayment,
          chartOfAccounts: this.chartOfAccounts,
          dueDate: this.dueDate,
          paid: this.paid
        }
      };

      this.putApi(params).then((response) => {
        if (response.success) {
          this.hideModal("createFinance");
          this.cleanSelected();
        }
        this.removeLoading(["confirm"]);
      });
    },
    confirmCreate() {
      this.removeLoading(["btnCreateFinance"]);
      this.openModal("createFinance");
    },
    getPdf(chave) {
      let params = {
        url: this.urlGetPdf,
        obj: {
          chave: chave,
          fileName: `nfe_${chave}`,
          fileType: "pdf"
        },
      };
      this.getFileApi(params).then(() => { });
    },
    getXml(chave) {
      let params = {
        url: this.urlGetXml,
        obj: {
          chave: chave,
          fileName: `nfe_${chave}`,
          fileType: "xml"
        },
      };
      this.getFileApi(params).then(() => { });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "getPdfNfe") {
          this.getPdf(event.data.chaveNfe);
        }

        if (event.name == "getXmlNfe") {
          this.getXml(event.data.chaveNfe);
        }

      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-paid {
  margin-top: 40px;
}
</style>